import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { ProductCard } from "./ProductCard"
import { ProductAlertSection } from "./ProductAlertSection"

interface ExcessiveProductsProps {
  excessiveProductIds: string[] | undefined
  excessiveProducts: AllMercurialInfo[]
  allItems: AllMercurialInfo[]
}

export const ExcessiveProducts = ({
  excessiveProductIds,
  excessiveProducts,
  allItems,
}: ExcessiveProductsProps) => {
  return (
    <ProductAlertSection
      title={`Quantités excessives : ${excessiveProducts.length}`}
      message={
        excessiveProducts.length > 0
          ? "Attention ces quantités risquent d'augmenter la démarque"
          : "Toutes les quantités sont correctes"
      }
      isAllFixed={excessiveProducts.length === 0}
    >
      {(excessiveProductIds ?? []).map((productId, index) => {
        const product = allItems.find(
          (item) => item.mercuriale_id === productId,
        )
        if (!product) return null

        const isFix = excessiveProducts.includes(product)

        return <ProductCard key={index} product={product} isFix={isFix} />
      })}
    </ProductAlertSection>
  )
}
