import { ProductCard } from "./ProductCard"
import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { ProductAlertSection } from "./ProductAlertSection"

interface TooLowProductsProps {
  tooLowProductIds: string[] | undefined
  tooLowProducts: AllMercurialInfo[]
  allItems: AllMercurialInfo[]
}

export const TooLowProducts = ({
  tooLowProductIds,
  tooLowProducts,

  allItems,
}: TooLowProductsProps) => (
  <ProductAlertSection
    title={`Risques de rupture : ${tooLowProducts.length}`}
    message="Attention ces quantités semblent trop faibles"
    isAllFixed={tooLowProducts.length === 0}
  >
    {(tooLowProductIds ?? []).map((productId, index) => {
      const product = allItems.find((item) => item.mercuriale_id === productId)
      if (!product) return null

      const isFix = tooLowProducts.includes(product)

      return <ProductCard key={index} product={product} isFix={isFix} />
    })}
  </ProductAlertSection>
)
