import { Skeleton } from "@/components/ui/skeleton"
import { Img } from "react-image"
import { InventoryInput } from "../InventoriesInputs/InventoryInput"
import { InventoryType } from "@/utils/__generated__/graphql"
import { getUnit } from "@/utils/getUnit"
import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { ReceiptPercentIcon } from "@heroicons/react/24/solid"

export function UsedToBePromoCard({
  references,
  companyId,
  updateInventory,
}: {
  references: AllMercurialInfo[]
  companyId: string | null
  updateInventory: (value: string) => Promise<void>
}) {
  return (
    <div className="bg-gray-100 rounded text-left lg:mr-12">
      <div className="px-4 pt-4 flex gap-4 items-center">
        <ReceiptPercentIcon className="size-5 text-gray-500" />
        <div>
          <p className="font-bold">Anciennes promotions</p>
          <p className="font-medium text-sm italic">
            Capacité linéaire à renseigner
          </p>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row lg:flex-wrap gap-2 p-4">
        {references.map((reference) => (
          <div
            key={reference.mercuriale_id!}
            className="lg:w-[calc(50%-4px)] flex flex-row items-start justify-between gap-1 bg-white shadow rounded p-2"
          >
            <div className="flex-1">
              <p className="text-sm font-bold line-clamp-2">
                {reference.mercuriale_name}
              </p>
              <div className="flex items-center lg:items-start gap-2">
                {typeof reference.photo_id === "string" && (
                  <Img
                    src={`https://storage.googleapis.com/references_images/${companyId}/${reference.photo_id}.jpeg`}
                    className="w-12 lg:w-9 h-16 lg:h-11 rounded object-cover"
                    loader={
                      <Skeleton className="w-12 lg:w-9 h-16 lg:h-11 rounded" />
                    }
                  />
                )}
                <div className="text-left">
                  <p className="text-sm font-medium text-gray-500">
                    {reference.colisage} {getUnit(reference.unit ?? "")}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-32 md:w-fit">
              <InventoryInput
                name={InventoryType.Shelf}
                mercurialeId={reference.mercuriale_id!}
                unit={reference.unit ?? ""}
                isNewReference={reference.new_reference ?? false}
                value={reference.shelf_floor_size ?? 0}
                floorInventoryQuantity={reference.floor_inventory_qty ?? 0}
                updateInventory={updateInventory}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
