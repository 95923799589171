import { FormEventHandler, useEffect, useMemo, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import "../../styles/inventory-page.css"
import InventoryNav from "./InventoryNav"

import { type DispatchActionType, type StateType } from "../../types"
import {
  MercurialReducerState,
  type AllMercurialInfo,
} from "../../reducers/mercurialReducer"
import NewLoading from "../loading/NewLoading"
import { Sidebar } from "./Sidebar"
import FilterSelect from "./FilterSelect"
import { Input } from "../../ui/Input"
import { SearchIcon } from "../../ui/icons/SearchIcon"
import { ItemContent, VirtuosoHandle } from "react-virtuoso"
import { Button } from "../../ui/Button"
import { ChevronUpIcon } from "@heroicons/react/24/outline"
import { useWindowSize } from "../../hooks/useWindowSize"
import { Modal } from "../../ui/Modal"
import {
  CalendarAlertIcon,
  RefreshAlertIcon,
  SaveAlertIcon,
  WifiAlertIcon,
} from "../../ui/AlertIcons"
import { Spinner } from "../../ui/Spinner"
import { formatDateToYYYYMMDD } from "../../utils/formatDateToYYYYMMDD"
import { getDiffBetweenDates } from "../../utils/getDiffBetweenDates"
import { MobileInventoryRecap } from "./MobileInventoryRecap"
import { useNavigate, useOutletContext } from "react-router-dom"
import { InventoryContext } from "./InventoryRoot"
import {
  BatchUpdateData,
  BatchUpdateMutation,
  LogsModificationUpdateMutation,
  OrderPlanningUpsertItem,
  useBatchUpdateMutation,
  useLogsModificationUpdateMutation,
  useUpsertOrderPlanningMutation,
} from "../../utils/__generated__/graphql"
import { getBatchData } from "../../utils/getBatchData"
import { captureException } from "@sentry/react"
import { DataSynchronizationStatus } from "../../reducers/connectionReducer"
import { FetchResult } from "@apollo/client"
import { InventoryTable } from "./InventoryTable"
import { CheckInModal } from "./modals/CheckInModal"

type Data = Partial<AllMercurialInfo>[]

interface InventoryPageTemplateProps {
  page: "inventoryPage"
  loading?: boolean
  rawData: Data
  dataLength: number
  rowContent: ItemContent<unknown, unknown>
  updateInventory: (value: string) => Promise<void>
  searchedAmount: number
  filteredAmount: number
  unfilteredAmount: number
}

export function InventoryPageTemplate({
  page,
  loading = false,
  rawData,
  dataLength,
  rowContent,
  updateInventory,
  searchedAmount,
  filteredAmount,
  unfilteredAmount,
}: InventoryPageTemplateProps): JSX.Element {
  const dispatch = useDispatch<DispatchActionType>()
  const navigate = useNavigate()
  const [{ synchronisationLoading }] = useOutletContext<InventoryContext>()

  const { storeId, companyName, storeSuppliers } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const {
    searchTerm,
    displayShelfFloorSize,
    suppliersIds,
    familyName,
    subFamilyName,
  } = useSelector(
    (state: StateType) => state.userInterfaceReducer.inventoryPage,
  )
  const online = useSelector(
    (state: StateType) => state.connectionReducer.online,
  )
  const {
    updatedReferences,
    modifications,
    mercurialAndStoreInventories,
    dimMercuriales,
  } = useSelector((state: StateType) => state.mercurialReducer)

  const virtuoso = useRef<VirtuosoHandle>(null)
  const oldScrollTop = useRef<number>(0)

  const { height, isMD, isLG } = useWindowSize()
  const refreshModalCloseDate = useSelector(
    (state: StateType) => state.updateReducer.refreshModalCloseDate,
  )
  const orderDate = useSelector(
    (state: StateType) => state.mercurialReducer.orderDate,
  )

  const [isOfflineRefreshModalOpen, setIsOfflineRefreshModalOpen] =
    useState(false)
  const [isDataSynchronizeModalOpen, setIsDataSynchronizeModalOpen] =
    useState(false)
  const [isRefreshModalOpen, setIsRefreshModalOpen] = useState(false)
  // const [isNoOrderTodayModalOpen, setIsNoOrderTodayModalOpen] = useState(false)
  const [isScrollingDown, setIsScrollingDown] = useState(false)
  const [saveDataModalState, setSaveDataModalState] = useState<{
    isOpen: boolean
    backToHome: boolean
  }>({ isOpen: false, backToHome: false })
  const [isOrderReceptionDateModalOpen, setIsOrderReceptionDateModalOpen] =
    useState(false)
  const [customOrderReceptionDates, setCustomOrderReceptionDates] = useState<
    Record<string, string>
  >({})

  const [batchUpdateMutation, { loading: batchUpdateLoading }] =
    useBatchUpdateMutation()
  const [
    logsModificationUpdateMutation,
    { loading: logsModificationUpdateLoading },
  ] = useLogsModificationUpdateMutation()
  const [upsertOrderPlanningMutation, { loading: upsertOrderPlanningLoading }] =
    useUpsertOrderPlanningMutation()

  function refreshApplication() {
    window.location.href = `/?date=${new Date().getTime()}`
  }

  const isValidOrderDate = useMemo(() => {
    if (loading) return true
    const currentDate = formatDateToYYYYMMDD(new Date())
    return orderDate === currentDate
  }, [loading, orderDate])

  // Prevent refesh when iternet connection is offline
  useEffect(() => {
    const beforeUnloadListener: EventListenerOrEventListenerObject = (
      event,
    ) => {
      if (window.navigator.onLine) return
      event.preventDefault()
      // Chrome requires returnValue to be set.
      event.returnValue = false
    }

    window.addEventListener("beforeunload", beforeUnloadListener)

    return () => {
      window.removeEventListener("beforeunload", beforeUnloadListener)
    }
  }, [])

  useEffect(() => {
    if (window.location.search.includes("date")) {
      window.history.replaceState({}, document.title, window.location.pathname)
      setIsDataSynchronizeModalOpen(true)
      return
    }
    if (!loading) {
      setIsDataSynchronizeModalOpen(false)
    }
  }, [loading])

  useEffect(() => {
    const currentDate = new Date()
    if (
      isValidOrderDate ||
      (refreshModalCloseDate !== undefined &&
        getDiffBetweenDates(currentDate, new Date(refreshModalCloseDate)) >= 0)
    ) {
      if (companyName === "biomonde") {
        setIsOrderReceptionDateModalOpen(false)
        return
      }
      setIsRefreshModalOpen(false)
      return
    }

    if (companyName === "biomonde") {
      setIsOrderReceptionDateModalOpen(true)
      return
    }
    setIsRefreshModalOpen(true)
  }, [companyName, isValidOrderDate, refreshModalCloseDate])

  useEffect(() => {
    const orderReceptionDates = mercurialAndStoreInventories.reduce<
      Record<string, string>
    >((acc, curr) => {
      if (
        typeof curr.supplier_id !== "string" ||
        typeof curr.mercuriale_reception_date !== "string" ||
        acc[curr.supplier_id] !== undefined
      ) {
        return acc
      }

      return {
        ...acc,
        [curr.supplier_id ?? ""]: curr.mercuriale_reception_date ?? "",
      }
    }, {})
    setCustomOrderReceptionDates(orderReceptionDates)
  }, [mercurialAndStoreInventories])

  const handleSearchTerm: FormEventHandler<HTMLInputElement> = (event) => {
    dispatch({
      type: "setSearchBar",
      payload: { page, searchTerm: event.currentTarget.value },
    })
  }

  useEffect(() => {
    if ((isMD && !isLG) || !displayShelfFloorSize) return
    dispatch({
      type: "toggleDisplayShelfFloorSize",
      payload: { page: "inventoryPage" },
    })
  }, [dispatch, displayShelfFloorSize, isLG, isMD, page])

  useEffect(() => {
    setIsScrollingDown(false)
  }, [rawData])

  useEffect(() => {
    if (online && isOfflineRefreshModalOpen) {
      refreshApplication()
    }
  }, [isOfflineRefreshModalOpen, online])

  const hiddenReferences = useMemo(() => {
    if (
      suppliersIds === undefined &&
      familyName === undefined &&
      subFamilyName === undefined
    ) {
      return unfilteredAmount - filteredAmount
    }
    return searchedAmount - filteredAmount
  }, [
    familyName,
    filteredAmount,
    searchedAmount,
    subFamilyName,
    suppliersIds,
    unfilteredAmount,
  ])
  const mercurialeDate = rawData?.[0]?.date_integration

  return (
    <div className="h-svh mh:h-screen relative bg-gray-40 overflow-hidden flex flex-col">
      <Modal
        icon={<SaveAlertIcon />}
        title="Tentative de sauvegarde échouée"
        open={saveDataModalState.isOpen}
        hideCloseButton
        onClose={() => {
          setSaveDataModalState({ isOpen: false, backToHome: false })
        }}
      >
        <p className="text-[#667085] text-sm font-normal text-center">
          Votre connexion internet est instable. Veuillez résoudre vos problèmes
          de réseau et réessayer de sauvegarder vos données en cliquant sur le
          bouton ci-dessous.
        </p>
        <Button
          onClick={async () => {
            if (modifications.length <= 0) return
            const notSavedReferences = Object.values(updatedReferences).reduce<
              MercurialReducerState["updatedReferences"]
            >((acc, curr) => {
              if (
                modifications.some(
                  (modification) =>
                    modification.mercuriale_id === curr.mercurialeId,
                )
              ) {
                acc[curr.mercurialeId] = curr
                return acc
              }
              return acc
            }, {})
            const batchData = getBatchData(
              mercurialAndStoreInventories,
              notSavedReferences,
            )
            try {
              let batchUpdateResults:
                | FetchResult<BatchUpdateMutation>[]
                | undefined = undefined

              const filteredBatchDatas = dimMercuriales?.reduce<
                {
                  dimOrderRequestId: string | undefined
                  data: BatchUpdateData[]
                }[]
              >((acc, curr) => {
                const filteredBatchData = batchData.filter(
                  (data) => data.dim_mercuriale_id === curr.dimMercurialeId,
                )
                if (filteredBatchData.length === 0) return acc
                return [
                  ...acc,
                  {
                    dimOrderRequestId: curr.dimOrderRequestId,
                    data: filteredBatchData,
                  },
                ]
              }, [])

              if (filteredBatchDatas !== undefined) {
                batchUpdateResults = await Promise.all(
                  filteredBatchDatas.map((filteredBatchData) =>
                    batchUpdateMutation({
                      variables: {
                        input: {
                          batch_data: filteredBatchData.data,
                          dim_order_request_id:
                            filteredBatchData.dimOrderRequestId,
                          store_id: storeId ?? "",
                          inventory_type: undefined,
                        },
                      },
                    }),
                  ),
                )

                batchUpdateResults.forEach((result) => {
                  if (result.data?.batchUpdate?.error !== null) {
                    throw result.data?.batchUpdate.error
                  }
                })
              }

              let logsModificationUpdateResult:
                | FetchResult<LogsModificationUpdateMutation>
                | undefined = undefined

              logsModificationUpdateResult =
                await logsModificationUpdateMutation({
                  variables: {
                    input: {
                      store_id: storeId ?? "",
                      modifications_logs_items: modifications,
                    },
                  },
                })
              if (
                logsModificationUpdateResult.data?.logsModificationUpdate
                  ?.error !== null
              ) {
                throw logsModificationUpdateResult.data?.logsModificationUpdate
                  ?.error
              }

              batchUpdateResults?.forEach((batchUpdateResult, i) => {
                const dimMercurialeId =
                  filteredBatchDatas?.[i].data[0].dim_mercuriale_id
                if (
                  typeof batchUpdateResult?.data?.batchUpdate
                    .dim_order_request_id === "string" &&
                  typeof dimMercurialeId === "string"
                ) {
                  dispatch({
                    type: "setDimOrderRequestId",
                    payload: {
                      dimMercurialeId: dimMercurialeId,
                      dimOrderRequestId:
                        batchUpdateResult.data?.batchUpdate
                          .dim_order_request_id,
                    },
                  })
                }
              })
              dispatch({
                type: "setModifications",
                payload: [],
              })
              dispatch({
                type: "setDataSynchronizationStatus",
                payload: DataSynchronizationStatus.SYNCHRONIZED,
              })
              setSaveDataModalState({ isOpen: false, backToHome: false })
              if (saveDataModalState.backToHome) {
                navigate("/account/calendar")
              }
            } catch (error) {
              console.error(error)
              captureException(error)
              dispatch({
                type: "setSnackbar",
                payload: {
                  type: "error",
                  message: "Données non sauvegardées",
                },
              })
            }
          }}
          loading={batchUpdateLoading || logsModificationUpdateLoading}
          className="w-full h-11 text-base mt-4 md:mt-8"
        >
          Réessayer
        </Button>
        <Button
          onClick={() => {
            setSaveDataModalState({ isOpen: false, backToHome: false })
            if (saveDataModalState.backToHome) {
              navigate("/account/calendar")
            }
          }}
          className="w-full h-11 text-base"
          color="ghost"
        >
          Annuler
        </Button>
      </Modal>
      <Modal
        icon={<RefreshAlertIcon />}
        title="Synchronisation des données."
        open={isDataSynchronizeModalOpen}
        hideCloseButton
      >
        <p className="text-[#667085] text-sm font-normal text-center">
          Veuillez patienter pendant la mise à jour de vos données. Cette
          fenêtre se fermera toute seule une fois la mise à jour terminée.
        </p>
        <div className="flex justify-center mt-4 md:mt-8">
          <Spinner />
        </div>
      </Modal>
      <Modal
        icon={<WifiAlertIcon />}
        title="Vous n'êtes pas connecté à internet."
        open={isOfflineRefreshModalOpen}
        onClose={() => {
          if (!isValidOrderDate) return
          setIsOfflineRefreshModalOpen(false)
        }}
        hideCloseButton
      >
        <p className="text-[#667085] text-sm font-normal text-center">
          Veuillez vous connecter à internet pour mettre à jour les données de
          l&apos;application. Si vous gardez cette fenêtre ouverte, les données
          seront récupérées automatiquement une fois la connexion rétablie.
        </p>
        {isValidOrderDate && (
          <Button
            onClick={() => setIsOfflineRefreshModalOpen(false)}
            className="w-full h-11 text-base mt-4 md:mt-8"
            color="ghost"
          >
            Annuler
          </Button>
        )}
      </Modal>
      <Modal
        icon={<RefreshAlertIcon />}
        title="Vos données ne sont pas à jour."
        open={isRefreshModalOpen}
        hideCloseButton
      >
        <p className="text-[#667085] text-sm font-normal text-center">
          Les données de l&apos;application ne sont pas à jour. Veuillez cliquer
          sur le bouton ci-dessous pour les mettre à jour.
        </p>
        <Button
          onClick={() => {
            if (!online) {
              setIsOfflineRefreshModalOpen(true)
              setIsRefreshModalOpen(false)
              return
            }
            refreshApplication()
          }}
          className="w-full h-11 text-base mt-4 md:mt-8"
          color="green"
        >
          Mettre à jour
        </Button>
        <Button
          onClick={() => {
            dispatch({
              type: "setRefreshModalCloseDate",
              payload: new Date().toString(),
            })
            setIsRefreshModalOpen(false)
          }}
          className="w-full h-11 text-base"
          color="ghost"
        >
          Annuler
        </Button>
      </Modal>
      <Modal
        icon={<CalendarAlertIcon />}
        title="Dates de réception manquantes"
        open={isOrderReceptionDateModalOpen}
        hideCloseButton
        onClose={() => setIsOrderReceptionDateModalOpen(false)}
      >
        <div className="mt-4 flex flex-col items-center gap-2">
          {storeSuppliers
            ?.filter((supplier) =>
              mercurialAndStoreInventories.some(
                (mercurialeInfo) => mercurialeInfo.supplier_id === supplier.id,
              ),
            )
            .map((supplier) => {
              return (
                <div key={supplier.id}>
                  <p>{supplier?.supplier_name}</p>
                  <input
                    type="date"
                    className="w-fit h-[44px] mt-2 p-2 border border-gray-200 rounded shadow"
                    value={customOrderReceptionDates[supplier.id] ?? ""}
                    onChange={(e) =>
                      setCustomOrderReceptionDates(
                        (prevCustomOrderReceptionDates) => ({
                          ...prevCustomOrderReceptionDates,
                          [supplier.id]: e.target.value,
                        }),
                      )
                    }
                  />
                </div>
              )
            })}
        </div>
        <Button
          onClick={async () => {
            const orderDate = formatDateToYYYYMMDD(new Date())

            try {
              const planningItems = dimMercuriales?.reduce<
                OrderPlanningUpsertItem[]
              >((acc, dimMercuriale) => {
                const suppliersIds = storeSuppliers
                  ?.map((supplier) => supplier.id)
                  .filter(
                    (supplierId) =>
                      customOrderReceptionDates[supplierId] !== undefined &&
                      customOrderReceptionDates[supplierId] !== "",
                  )
                return [
                  ...acc,
                  ...(suppliersIds?.map((supplierId) => ({
                    order_date: orderDate,
                    reception_date: customOrderReceptionDates[supplierId],
                    supplier_id: supplierId,
                    dim_mercuriale_id: dimMercuriale.dimMercurialeId,
                  })) ?? []),
                ]
              }, [])
              if (planningItems === undefined || planningItems.length === 0)
                return

              const upsertOrderPlanningResult =
                await upsertOrderPlanningMutation({
                  variables: {
                    input: {
                      store_id: storeId!,
                      planning_item_upsert_input: planningItems,
                    },
                  },
                })
              if (
                upsertOrderPlanningResult.data?.upsertOrderPlanning.success !==
                true
              ) {
                throw "La création du planning a échouée"
              }
              refreshApplication()
            } catch (error) {
              console.error(error)
              captureException(error)
              dispatch({
                type: "setSnackbar",
                payload: {
                  type: "error",
                  message: "La création du planning a échouée",
                },
              })
            }
          }}
          disabled={
            Object.values(customOrderReceptionDates).filter(
              (value) => value !== "",
            ).length <= 0
          }
          loading={upsertOrderPlanningLoading}
          className="w-full h-11 mt-8"
        >
          Valider
        </Button>
      </Modal>
      <CheckInModal
        updateInventory={updateInventory}
        isLoading={loading}
        isValidOrderDate={isValidOrderDate}
      />
      <InventoryNav
        page={page}
        isLoading={synchronisationLoading}
        onRefreshButtonClick={() => setIsOfflineRefreshModalOpen(true)}
        setSaveDataModalState={setSaveDataModalState}
      />
      <main className="flex-1 h-full flex flex-col relative z-0">
        {/* Page title & actions */}
        <div className="flex flex-1 justify-between h-full relative">
          {/* Left Column */}
          <div className="flex-1 flex flex-col lg:gap-1 relative">
            <div
              className={`absolute z-20 top-0 left-0 w-full origin-top bg-gray-40 flex items-center p-2 xl:px-4 ${!isMD && isScrollingDown ? "scale-y-0" : "scale-y-100"} transition-all duration-500`}
            >
              <div className="w-fit mr-2">
                <FilterSelect page={page} />
              </div>
              <div className="flex-1 mr-2">
                <Input
                  name="search"
                  type="text"
                  placeholder={`Rechercher${familyName !== undefined && subFamilyName === undefined && familyName !== "autres" ? ` parmi "${familyName}"` : ""}${subFamilyName !== undefined ? ` parmi "${subFamilyName}"` : ""}`}
                  icon={<SearchIcon className="w-4 h-4 lg:w-6 lg:h-6" />}
                  value={searchTerm}
                  onChange={handleSearchTerm}
                />
              </div>
              <p className="hidden lg:inline w-[100px] text-[#323232] text-xs font-bold">
                RÉSERVE
              </p>
              <p className="hidden lg:inline w-[90px] text-[#323232] text-xs font-bold">
                RAYON
              </p>
              <p className="hidden lg:inline w-[120px] text-[#323232] text-xs font-bold">
                TOTAL
              </p>
              <p className="hidden lg:inline text-[#323232] text-xs font-bold mr-[50px]">
                COMMANDE
              </p>
            </div>
            <div className="h-full w-full">
              {loading ? (
                <NewLoading />
              ) : (
                <InventoryTable
                  ref={virtuoso}
                  onScroll={(e) => {
                    const target = e.target as HTMLElement
                    if (
                      target.scrollHeight -
                        target.scrollTop -
                        target.clientHeight <
                        100 ||
                      target.scrollTop < 100
                    )
                      return
                    setIsScrollingDown(oldScrollTop.current < target.scrollTop)
                    oldScrollTop.current = target.scrollTop
                  }}
                  totalCount={dataLength}
                  header={() => <div className="h-14 lg:h-16" />}
                  footer={() => (
                    <div className="flex justify-center pt-2 pb-40 md:pb-12">
                      {hiddenReferences > 0 && (
                        <button
                          className="underline"
                          onClick={() => {
                            if (
                              suppliersIds === undefined &&
                              familyName === undefined &&
                              subFamilyName === undefined
                            ) {
                              dispatch({
                                type: "setSearchBar",
                                payload: {
                                  page: "inventoryPage",
                                  searchTerm: "",
                                },
                              })
                              return
                            }
                            dispatch({
                              type: "setFilters",
                              payload: {
                                page: "inventoryPage",
                                supplierId: undefined,
                                familyName: undefined,
                                subFamilyName: undefined,
                              },
                            })
                          }}
                        >
                          {hiddenReferences} référence
                          {hiddenReferences > 1 ? "s" : ""} cachée
                          {hiddenReferences > 1 ? "s" : ""} par les filtres
                          actuels
                        </button>
                      )}
                    </div>
                  )}
                  itemContent={rowContent}
                />
              )}
            </div>
          </div>
          {/* Right column */}
          <div className="hidden md:block w-[280px] h-full relative">
            <Sidebar
              hideWeather={(isMD && height < 750) || (isLG && height < 800)}
              updateInventory={updateInventory}
              setIsOrderReceptionDateModalOpen={
                setIsOrderReceptionDateModalOpen
              }
              className="mt-2 mr-2 gap-2"
              companyName={companyName ?? ""}
              previousOrderInventoryComputeDate={
                mercurialAndStoreInventories.find(
                  (mercurialeInfo) =>
                    typeof mercurialeInfo.previous_order_inventory_compute_date ===
                    "string",
                )?.previous_order_inventory_compute_date ?? undefined
              }
            />
          </div>
        </div>
        <section className="absolute bottom-0 z-20 w-full md:w-[calc(100%-280px)] pointer-events-none">
          <div className="flex justify-end p-2">
            <Button
              className="w-10 h-10 lg:w-8 lg:h-8 rounded-full p-0 bg-white hover:bg-slate-100 shadow text-green-500 pointer-events-auto"
              onClick={() => {
                virtuoso.current?.scrollTo({ top: 0 })
                setIsScrollingDown(false)
              }}
            >
              <ChevronUpIcon className="w-6 h-6 lg:w-5 lg:h-5" />
            </Button>
          </div>
          {!isMD && !loading && (
            <MobileInventoryRecap
              mercurialeDate={mercurialeDate ?? undefined}
              isLoading={synchronisationLoading}
              hide={isScrollingDown}
              setSaveDataModalState={setSaveDataModalState}
            />
          )}
        </section>
      </main>
    </div>
  )
}
