import { ProductQuantitySelector } from "@/components/organisms/productQuantitySelector"
import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { TableCell } from "@/components/ui/table"
import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { StateType } from "@/types"
import { getUnit } from "@/utils/getUnit"
import { Trash2 } from "lucide-react"
import { Img } from "react-image"
import { useSelector } from "react-redux"

interface DesktopTableRowProps {
  product: AllMercurialInfo
  online: boolean
  quantityActualInUnit: number
  storeCurrency: string | null
  handleDeleteProduct: (product: AllMercurialInfo) => void
}

export const DesktopTableRow = ({
  product,
  online,
  quantityActualInUnit,
  storeCurrency,
  handleDeleteProduct,
}: DesktopTableRowProps) => {
  const formatCurrency = (value: number, currency: string | null) => {
    return new Intl.NumberFormat("fr-FR", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      style: "currency",
      currency: currency ?? "EUR",
    }).format(value)
  }

  const { companyId } = useSelector((state: StateType) => state.storeReducer)

  const deleteProduct = () => {
    handleDeleteProduct(product)
  }

  return (
    <>
      <TableCell className="flex items-center flex-row gap-4 col-span-2">
        {online && product?.photo_id ? (
          <Img
            className="w-16 h-16 rounded object-cover bg-white border border-gray-300"
            src={`https://storage.googleapis.com/references_images/${companyId}/${product.photo_id}.jpeg`}
            alt={product?.mercuriale_name ?? ""}
            unloader={
              <div className="w-16 h-16 min-w-16 min-h-16 rounded object-cover bg-white border border-gray-300" />
            }
          />
        ) : (
          <div className="w-16 h-16 min-w-16 min-h-16 rounded object-cover bg-white border border-gray-300" />
        )}
        <p className="flex flex-col items-start">
          <span className="text-left text-md sm:text-sm md:text-md uppercase text-black font-bold line-clamp-2">
            {product?.mercuriale_name ?? ""}
          </span>
        </p>
      </TableCell>
      <TableCell className="hidden md:table-cell p-0 text-center">
        {typeof product.colisage === "number"
          ? `${product.colisage} ${getUnit(product.unit)}`
          : "-"}
      </TableCell>
      <TableCell className="hidden md:hidden lg:table-cell ">
        <p className="text-center">
          {typeof product.pv === "number"
            ? formatCurrency(product.pv, storeCurrency)
            : "-"}
        </p>
      </TableCell>
      <TableCell className="table-cell col-span-2 lg:col-span-1 items-center justify-center">
        <div className="flex align-center justify-center w-full">
          <ProductQuantitySelector product={product} />
        </div>
      </TableCell>
      <TableCell className="flex flex-row gap-2 py-0 px-4 justify-center items-center">
        <p>
          {typeof product.pa === "number" &&
          !isNaN(product.pa * quantityActualInUnit) &&
          product.pa * quantityActualInUnit > 0
            ? formatCurrency(product.pa * quantityActualInUnit, storeCurrency)
            : "-"}
        </p>
      </TableCell>
      <TableCell className="flex flex-row justify-center items-center gap-2 p-0">
        <p>
          {typeof product.pv === "number" &&
          !isNaN(product.pv * quantityActualInUnit) &&
          product.pv * quantityActualInUnit > 0
            ? formatCurrency(product.pv * quantityActualInUnit, storeCurrency)
            : "-"}
        </p>
      </TableCell>
      <TableCell className="flex justify-center items-center">
        <Dialog>
          <DialogTrigger>
            <Trash2 className="cursor-pointer" />
          </DialogTrigger>
          <DialogContent className="max-w-screen-sm md:min-h-min md:max-h-min">
            <DialogTitle className="text-md md:text-lg">
              Voulez-vous vraiment retirer {product.mercuriale_name} de votre
              commande ?
            </DialogTitle>
            <DialogClose className="flex flex-row justify-end gap-2">
              <Button variant="outline">Annuler</Button>
              <Button
                className="bg-red-600 hover:bg-red-700"
                onClick={deleteProduct}
              >
                Supprimer
              </Button>
            </DialogClose>
          </DialogContent>
        </Dialog>
      </TableCell>
    </>
  )
}
