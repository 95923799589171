import { FireIcon } from "@heroicons/react/16/solid"
import { AllMercurialInfo } from "../../reducers/mercurialReducer"
import { StoreReducerState } from "../../reducers/storeReducer"
import { MercurialeInfo } from "../../utils/__generated__/graphql"
import { Square3Stack3DIcon } from "@heroicons/react/24/solid"
import { UserInterfaceReducerState } from "../../reducers/userInterfaceReducer"
import { LastPerformingTable } from "../../ui/LastPerformingTable"
import { Badges } from "../../ui/Badges"
import { ReferenceDetails } from "../../ui/ReferenceDetails"
import { Fragment, useMemo, useState } from "react"
import { Modal } from "../../ui/Modal"
import { Img } from "react-image"
import { useWindowSize } from "../../hooks/useWindowSize"
import LocalFlagToggle from "../button/LoggleFlagButton"
import { Delivery } from "../../ui/Delivery"
import { twJoin, twMerge } from "tailwind-merge"
import { usePermissions } from "@/hooks/usePermissions"
import { InventoriesInputs } from "./InventoriesInputs/InventoriesInputs"

export interface RowProps {
  index: number
  bestSellers: MercurialeInfo[]
  updateInventory: (
    value: string,
    selectedInventory?: UserInterfaceReducerState["selectedInventory"],
  ) => Promise<void>
  displayShelfFloorSize: boolean
  storeSettings: StoreReducerState["storeSettings"]
  storeSuppliers: StoreReducerState["storeSuppliers"]
  storeCurrency: StoreReducerState["storeCurrency"]
  companyId: StoreReducerState["companyId"]
  storeId: StoreReducerState["storeId"]
  references: Partial<AllMercurialInfo>[]
  isOnline: boolean
  selectedInventory: UserInterfaceReducerState["selectedInventory"]
  isMinimal?: boolean
}

const imageLoader = (
  <div className="w-8 h-8 md:w-11 md:h-11 rounded-full bg-gray-300 animate-pulse" />
)

export function InventoryRow({
  bestSellers,
  storeSettings,
  updateInventory,
  storeSuppliers,
  storeCurrency,
  companyId,
  isOnline,
  storeId,
  references,
  selectedInventory,
  isMinimal = false,
}: RowProps) {
  const { isLG } = useWindowSize()
  const { isIdaAdmin } = usePermissions()
  const [isOpen, setIsOpen] = useState(false)

  const orderableReferences = useMemo(() => {
    return references.filter((reference) => {
      const isSoldButNonActive = reference.sold_but_not_active ?? false
      return (
        reference.active !== false &&
        typeof reference.mercuriale_reception_date === "string" &&
        !isSoldButNonActive
      )
    })
  }, [references])

  const notOrderableReferences = useMemo(() => {
    return references.filter(
      (reference) =>
        !orderableReferences.some(
          (orderableReference) =>
            orderableReference.mercuriale_id === reference.mercuriale_id,
        ),
    )
  }, [orderableReferences, references])

  const sortedReferences = useMemo(() => {
    // Sort orderable references
    const sortedOrderableReferences = [...orderableReferences].sort((a, b) => {
      if (
        (a.quantity_predicted_array ?? []).length > 0 &&
        (b.quantity_predicted_array ?? []).length === 0
      ) {
        return -1
      }
      if (
        (a.quantity_predicted_array ?? []).length === 0 &&
        (b.quantity_predicted_array ?? []).length > 0
      ) {
        return 1
      }

      return (a.pa ?? 0) - (b.pa ?? 0)
    })

    // Add not orderable references at the end
    return [...sortedOrderableReferences, ...notOrderableReferences]
  }, [notOrderableReferences, orderableReferences])

  const firstRow = sortedReferences[0]
  const isBestSeller = bestSellers.some(
    (bestSeller) => bestSeller.mercuriale_id === firstRow?.mercuriale_id,
  )

  const isActive =
    (firstRow.active ?? true) ||
    firstRow.sold_but_not_active === true ||
    firstRow.mercuriale_id === selectedInventory?.mercurialeId

  const bestMargin = useMemo(() => {
    if (sortedReferences.length <= 1) {
      return null
    }
    const margins = sortedReferences.map((reference) => {
      if (
        typeof reference.pa === "number" &&
        reference.pa > 0 &&
        typeof reference.pv === "number" &&
        reference.pv > 0
      ) {
        return (reference.pv - reference.pa) / reference.pv
      }
      return -Infinity
    })

    const uniqueMargins = [...new Set(margins)]
    if (uniqueMargins.length === 1) {
      return null
    }

    return Math.max(...margins)
  }, [sortedReferences])

  const hasBigBreakage = useMemo(() => {
    return references.some(
      (reference) =>
        typeof reference.breakage_percentage === "number" &&
        reference.breakage_percentage > 10,
    )
  }, [references])

  const itemIsNew = useMemo(() => {
    return references.some((reference) => reference.new_reference === true)
  }, [references])

  return (
    <>
      <Modal
        title={firstRow?.mercuriale_name}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <Badges
          expanded
          storeSuppliers={storeSuppliers}
          data={firstRow}
          isBestSeller={isBestSeller}
        />
        {isOnline && typeof firstRow.photo_id === "string" && (
          <Img
            src={`https://storage.googleapis.com/references_images/${companyId}/${firstRow?.photo_id}.jpeg`}
            className="rounded"
          />
        )}
        <LastPerformingTable
          isIdaAdmin={isIdaAdmin}
          colisage={firstRow?.colisage}
          predictionHistoricalField={firstRow?.prediction_historical_field}
          inventoryHistoricalField={firstRow?.inventory_historical_field}
          saleHistoricalQuantities={firstRow?.sale_historical_quantities}
          deliveryHistoricalQuantities={
            firstRow?.delivery_historical_quantities
          }
          orderExpectedReceptionDate={
            firstRow?.order_expected_reception_date ??
            firstRow.mercuriale_reception_date
          }
          orderNextExpectedReceptionDate={
            firstRow?.order_next_expected_reception_date
          }
          viewSalesFirst={storeSettings?.view_sales_first ?? false}
        />
      </Modal>
      <div
        className={twJoin(
          "relative flex",
          !isActive && "opacity-20",
          isMinimal && "w-full",
        )}
      >
        <div
          className={twMerge(
            "bg-white shadow rounded px-2 pt-2 pb-4 lg:pb-3 flex-1 z-10 flex flex-col gap-1",
            itemIsNew && "shadow border-2 border-yellow-600 ",
            hasBigBreakage && "shadow border-2 border-red-600",
            isMinimal && "shadow-none border-none p-0 rounded-none",
          )}
        >
          {sortedReferences.map((reference, i) => {
            const isBackInventoryInvalid =
              (reference?.back_inventory_qty ?? 0) < 0
            const isFloorInventoryInvalid =
              (reference?.floor_inventory_qty ?? 0) < 0
            const isOrderDisabled =
              isBackInventoryInvalid ||
              isFloorInventoryInvalid ||
              notOrderableReferences.some(
                (notOrderableReference) =>
                  notOrderableReference.mercuriale_id ===
                  reference.mercuriale_id,
              ) ||
              reference.stock_to_verify_flag === true

            return (
              <Fragment key={i}>
                <div className="flex gap-0 lg:gap-4 items-center justify-between">
                  <div className="flex flex-1 items-center gap-2">
                    {isBestSeller && i === 0 && (
                      <FireIcon className="w-4 h-4 text-red-700" />
                    )}

                    {i > 0 && (
                      <Square3Stack3DIcon className="w-4 h-4 text-neutral-500" />
                    )}
                    <div className="flex flex-1 overflow-hidden w-0">
                      <h2
                        className={`${i === 0 ? "font-black text-[#323232]" : " py-3 text-xs  font-bold text-neutral-500"} truncate`}
                      >
                        {i > 0 && (
                          <>
                            {i + 1}
                            <sup>ème</sup>
                          </>
                        )}{" "}
                        {reference?.mercuriale_name}
                      </h2>
                    </div>
                  </div>
                  <div className="flex gap-2 pl-1">
                    <div
                      className={`hidden lg:block lg:min-w-[73px] ${i > 0 ? "mb-1" : ""}`}
                    >
                      {(typeof reference?.order_expected_reception_date ===
                        "string" ||
                        typeof reference?.mercuriale_reception_date ===
                          "string") && (
                        <Delivery
                          date={
                            reference?.order_expected_reception_date ??
                            reference?.mercuriale_reception_date ??
                            ""
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div className="lg:hidden">
                    {storeSettings?.view_local && (
                      <LocalFlagToggle firstRow={firstRow} storeId={storeId} />
                    )}
                  </div>
                </div>
                <Badges
                  data={reference}
                  storeSuppliers={storeSuppliers}
                  isBestSeller={isBestSeller}
                  hideBreakageLabel={i > 0}
                  hide={!isLG}
                />
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col lg:flex-row gap-2 lg:gap-0">
                    <div
                      className={`flex-1 flex flex-col gap-2 ${sortedReferences.length > 1 ? "lg:border-l-2 lg:border-neutral-500 lg:ml-[7px] lg:pl-3" : ""}`}
                    >
                      <div className="flex gap-2 items-center">
                        {isOnline &&
                          typeof firstRow?.photo_id === "string" &&
                          i === 0 && (
                            <Img
                              src={`https://storage.googleapis.com/references_images/${companyId}/${firstRow?.photo_id}.jpeg`}
                              className="w-12 lg:w-9 h-16 lg:h-11 rounded object-cover cursor-pointer"
                              loader={imageLoader}
                              onClick={() => setIsOpen(true)}
                            />
                          )}
                        <div className="flex flex-col gap-1 overflow-x-auto w-full">
                          <Badges
                            data={reference}
                            storeSuppliers={storeSuppliers}
                            isBestSeller={isBestSeller}
                            hideBreakageLabel={i > 0}
                            hide={isLG}
                          />
                          <ReferenceDetails
                            bestMargin={bestMargin}
                            data={reference}
                            storeCurrency={storeCurrency}
                            storeSettings={storeSettings}
                          />
                        </div>
                      </div>
                      {i === 0 && (
                        <div className="flex justify-center lg:justify-start w-full">
                          <LastPerformingTable
                            isIdaAdmin={isIdaAdmin}
                            colisage={reference?.colisage}
                            saleHistoricalQuantities={
                              reference?.sale_historical_quantities
                            }
                            inventoryHistoricalField={
                              reference?.inventory_historical_field
                            }
                            predictionHistoricalField={
                              reference?.prediction_historical_field
                            }
                            deliveryHistoricalQuantities={
                              reference?.delivery_historical_quantities
                            }
                            orderExpectedReceptionDate={
                              reference?.order_expected_reception_date ??
                              reference.mercuriale_reception_date
                            }
                            orderNextExpectedReceptionDate={
                              reference?.order_next_expected_reception_date
                            }
                            viewSalesFirst={
                              storeSettings?.view_sales_first ?? true
                            }
                          />
                        </div>
                      )}
                    </div>
                    <InventoriesInputs
                      firstReference={firstRow}
                      reference={reference}
                      referenceIndex={i}
                      isOnline={isOnline}
                      isOrderDisabled={isOrderDisabled}
                      storeSettings={storeSettings}
                      updateInventory={updateInventory}
                    />
                  </div>
                </div>
                {sortedReferences.length > 1 && (
                  <hr className="last:hidden lg:hidden my-2" />
                )}
              </Fragment>
            )
          })}
        </div>
        <div className="hidden lg:block w-12 my-1">
          {storeSettings?.view_local && (
            <LocalFlagToggle firstRow={firstRow} storeId={storeId} />
          )}
        </div>
      </div>
    </>
  )
}
