import { Modal } from "../../../ui/Modal"
import { InventoryTable } from "../InventoryTable"
import { useDispatch, useSelector } from "react-redux"
import { selectedMercurialeInfosSelector } from "../../../selectors/mercurialeSelectors"
import { useMemo, useRef, useState } from "react"
import { InventoryRow } from "../InventoryRow"
import { DispatchActionType, StateType } from "../../../types"
import UpdateInventoryPad from "../UpdateInventoryPad"
import { VirtuosoHandle } from "react-virtuoso"
import { removeDuplicatesValues } from "../../../utils/removeDuplicates"
import { getDiffBetweenDates } from "../../../utils/getDiffBetweenDates"
import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { UsedToBePromoCard } from "./UsedToBePromoCard"
import { ProgressBar } from "./ProgressBar"

interface CheckInModalProps {
  updateInventory: (value: string) => Promise<void>
  isLoading: boolean
  isValidOrderDate: boolean
}

export function CheckInModal({
  updateInventory,
  isLoading,
  isValidOrderDate,
}: CheckInModalProps) {
  const dispatch = useDispatch<DispatchActionType>()
  const virtuosoRef = useRef<VirtuosoHandle>(null)
  const mercurialeInfos = useSelector(selectedMercurialeInfosSelector)
  const selectedDimMercurialeId = useSelector(
    (state: StateType) => state.mercurialReducer.selectedDimMercurialeId,
  )
  const checkInModalCloseDate = useSelector((state: StateType) =>
    selectedDimMercurialeId !== undefined
      ? state.mercurialReducer.checkInModalCloseDates[selectedDimMercurialeId]
      : undefined,
  )
  const { storeId, storeSettings, storeSuppliers, storeCurrency, companyId } =
    useSelector((state: StateType) => state.storeReducer)
  const isOnline = useSelector(
    (state: StateType) => state.connectionReducer.online,
  )
  const displayShelfFloorSize = useSelector(
    (state: StateType) =>
      state.userInterfaceReducer.inventoryPage.displayShelfFloorSize,
  )
  const selectedInventory = useSelector(
    (state: StateType) => state.userInterfaceReducer.selectedInventory,
  )

  const [scrollPosition, setScrollPosition] = useState(0)
  const [isEndReached, setIsEndReached] = useState(false)

  const referencesToVerify = useRef<string[]>()

  const promotionReferences = useMemo(() => {
    return mercurialeInfos.filter(
      (mercurialeInfo) =>
        mercurialeInfo.promotion === true &&
        referencesToVerify.current?.includes(
          mercurialeInfo.mercuriale_id ?? "",
        ) !== true,
    )
  }, [mercurialeInfos])

  const toVerifyReferences = useMemo(() => {
    if (referencesToVerify.current !== undefined) {
      return referencesToVerify.current
        .map((mercurialeId) =>
          mercurialeInfos.find(
            (mercurialeInfo) => mercurialeInfo.mercuriale_id === mercurialeId,
          ),
        )
        .filter((value): value is AllMercurialInfo => value !== undefined)
    }
    const filteredMercurialeInfos = mercurialeInfos.filter(
      (mercurialeInfo) => mercurialeInfo.stock_to_verify_flag === true,
    )
    // Store the references to verify in a ref to kept in memory
    referencesToVerify.current = filteredMercurialeInfos
      .map((mercurialeInfo) => mercurialeInfo.mercuriale_id)
      .filter((value): value is string => typeof value === "string")
    return filteredMercurialeInfos
  }, [mercurialeInfos])

  const newReferences = useMemo(() => {
    return mercurialeInfos.filter(
      (mercurialeInfo) => mercurialeInfo.new_reference === true,
    )
  }, [mercurialeInfos])

  const usedToBePromoReferences = useMemo(() => {
    return mercurialeInfos.filter(
      (mercurialeInfo) => mercurialeInfo.used_to_be_promo === true,
    )
  }, [mercurialeInfos])

  const filteredMercurialeInfos = useMemo(() => {
    return [
      ...promotionReferences,
      ...newReferences,
      ...toVerifyReferences,
      ...(usedToBePromoReferences.length > 0
        ? [usedToBePromoReferences[0]]
        : []),
    ]
  }, [
    newReferences,
    promotionReferences,
    toVerifyReferences,
    usedToBePromoReferences,
  ])

  const deduplicatedFilteredMercurialeInfos = useMemo(() => {
    return removeDuplicatesValues(filteredMercurialeInfos, "sale_id")
  }, [filteredMercurialeInfos])

  const isModalOpen = useMemo(() => {
    if (
      isLoading ||
      !isValidOrderDate ||
      storeSettings?.show_checkin_modal !== true ||
      deduplicatedFilteredMercurialeInfos.length === 0
    ) {
      return false
    }
    if (checkInModalCloseDate === undefined) return true

    if (getDiffBetweenDates(new Date(), new Date(checkInModalCloseDate)) < 0)
      return true

    return false
  }, [
    checkInModalCloseDate,
    deduplicatedFilteredMercurialeInfos.length,
    isLoading,
    isValidOrderDate,
    storeSettings?.show_checkin_modal,
  ])

  return (
    <Modal
      open={isModalOpen}
      border="gradient"
      fullScreen
      className="max-w-full overflow-y-auto bg-gray-300"
    >
      <div className="bg-white border rounded p-4 text-left">
        <h1 className="md:text-2xl font-bold">
          ⚡ Vos besoins du jour, simplifiés et organisés.
        </h1>
        <h2 className="text-xs md:text-sm text-[#4e4e4e]">
          Inspectez ces références, elles sont essentielles pour votre commande
          du jour.
        </h2>
        <ProgressBar
          scrollPosition={scrollPosition}
          virtuosoRef={virtuosoRef}
          promotionReferences={promotionReferences}
          newReferences={newReferences}
          toVerifyReferences={toVerifyReferences}
          usedToBePromoReferences={usedToBePromoReferences}
          deduplicatedFilteredMercurialeInfos={
            deduplicatedFilteredMercurialeInfos
          }
        />
      </div>
      <div className="h-full flex flex-col md:flex-row gap-2">
        <InventoryTable
          ref={virtuosoRef}
          onScroll={(e) => {
            const target = e.target as HTMLElement
            const maxScrollHeight = target.scrollHeight - target.clientHeight
            const position = (target.scrollTop / maxScrollHeight) * 100
            if (position < scrollPosition) return

            setScrollPosition(position)
          }}
          endReached={() => {
            setIsEndReached(true)
            const interval = setInterval(() => {
              setScrollPosition((_scrollPosition) => {
                if (_scrollPosition >= 100) {
                  clearInterval(interval)
                  return 100
                }
                return _scrollPosition + 5
              })
            }, 10)
          }}
          totalCount={deduplicatedFilteredMercurialeInfos.length}
          itemContent={(index) => {
            const row = deduplicatedFilteredMercurialeInfos[index]

            if (
              index === deduplicatedFilteredMercurialeInfos.length - 1 &&
              usedToBePromoReferences.length > 0
            ) {
              return (
                <UsedToBePromoCard
                  references={usedToBePromoReferences}
                  companyId={companyId}
                  updateInventory={updateInventory}
                />
              )
            }

            const references = filteredMercurialeInfos.filter(
              (mercurialeInfo) => mercurialeInfo.sale_id === row.sale_id,
            )
            return (
              <InventoryRow
                key={row.mercuriale_id!}
                index={index}
                storeId={storeId}
                bestSellers={[]}
                selectedInventory={selectedInventory}
                isOnline={isOnline}
                storeSettings={storeSettings}
                updateInventory={updateInventory}
                displayShelfFloorSize={displayShelfFloorSize}
                storeSuppliers={storeSuppliers}
                storeCurrency={storeCurrency}
                companyId={companyId}
                references={references}
              />
            )
          }}
        />
        <div className="w-full md:w-72 flex flex-col gap-2">
          <div className="hidden md:block w-full">
            <UpdateInventoryPad
              isLoading={false}
              updateInventory={updateInventory}
            />
          </div>
          <button
            className="w-full rounded bg-green-900 text-white font-black py-4 disabled:bg-gray-300 transition-colors"
            disabled={!isEndReached}
            onClick={() => {
              dispatch({
                type: "closeCheckInModal",
                payload: {
                  dimMercurialeId: selectedDimMercurialeId!,
                  date: new Date(),
                },
              })
            }}
          >
            SUIVANT
          </button>
        </div>
      </div>
    </Modal>
  )
}
